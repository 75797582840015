import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Host = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {
  const posts = data.allWpPost.nodes
  const host = data.wpHost

  return (
    <Layout isHomePage>
      <main id="main" className="site-main no-stretch dft-padding" role="main">
        <header className="entry-header">
          <h1 className="entry-title">Hosted by {parse(host.name)}</h1>
          <hr />
        </header>
        {host.description && <p className="mb-5">{parse(host.description)}</p>}

        <Helmet>
          <script type="application/ld+json">
            {`
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "name": "Point of Inquiry",
        "@id": "https://pointofinquiry.org/#website",
        "url": "https://pointofinquiry.org/",
        "isAccessibleForFree": true,
        "logo": {
              "@type": "ImageObject",
                "url": "https://cdn.centerforinquiry.org/wp-content/uploads/sites/30/2019/01/22164519/Point-of-Inquiry-Logo-2018.jpg"
  },
        "sourceOrganization": 
          {
            "@type": "Organization",
            "@id": "https://centerforinquiry.org/#organization",
            "name": " Center for Inquiry" 
                    }
              }     
          `}
          </script>
        </Helmet>

        {posts.map(post => {
          const title = post.title
          const featuredImage = {
            src:
              post.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData,
            alt: post.featuredImage?.node?.alt || ``,
          }

          return (
            <article
              className="blog-latest mb-5 wp-block-columns"
              itemScope
              itemType="http://schema.org/Article"
            >
              {featuredImage?.src && (
                <div
                  className="wp-block-column wrap-33-m"
                  style={{ flexBasis: "33.33%" }}
                >
                  <Link to={post.uri}>
                    <GatsbyImage
                      loading="lazy"
                      layout="fullWidth"
                      placeholder="blurred"
                      image={featuredImage.src}
                      className="article-thumbs"
                      alt={featuredImage.alt}
                      objectFit="cover"
                      objectPosition="50% 50%"
                      formats={"webp"}
                    />
                  </Link>
                </div>
              )}
              {post.episode.episodeImage && (
                <div className="wp-block-column wrap-33-m">
                  <Link to={post.uri}>
                    <div
                      className="img-fit article-thumbs legacy"
                      style={{
                        flexBasis: "33.33%",
                        backgroundImage:
                          "url(" + post.episode.episodeImage + ")",
                      }}
                    ></div>
                  </Link>
                </div>
              )}
              <div
                className="wp-block-column wrap-66-m"
                style={{ flexBasis: "66.66%" }}
              >
                <h2 className="h5">
                  <Link to={post.uri} itemProp="url">
                    <span itemProp="headline">{parse(title)}</span>
                  </Link>
                </h2>
                <span style={{ color: "#999" }}>{post.date}</span>
                <p className="blog-meta">
                  {post.hosts.nodes.map(node => (
                    <a href={node.uri}>{node.name}</a>
                  ))}
                </p>
                <section itemProp="description">{parse(post.excerpt)}</section>
              </div>
            </article>
          )
        })}
        <nav id="prev-next">
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `flex-end`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            {previousPagePath && (
              <Link to={previousPagePath} rel="prev">
                <li className="prev" key="previous">
                  <FaArrowLeft /> Previous
                </li>
              </Link>
            )}

            {nextPagePath && (
              <Link to={nextPagePath} rel="next">
                <li className="next" key="next">
                  Next <FaArrowRight />
                </li>
              </Link>
            )}
          </ul>
        </nav>
      </main>
    </Layout>
  )
}

export default Host

export const pageQuery = graphql`
  query GET_HOSTS($id: String!, $offset: Int!, $postsPerPage: Int!) {
    wpHost(id: { eq: $id }) {
      name
      description
    }
    allWpPost(
      sort: { order: DESC, fields: date }
      filter: { hosts: { nodes: { elemMatch: { id: { eq: $id } } } } }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

        hosts {
          nodes {
            name
            uri
          }
        }

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }

        episode {
          episodeImage
        }
      }
    }
  }
`
